<template>
  <form @submit.prevent="submitBody">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.nuevo')+$t('label.eirType')"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
            <CCol sm="12" lg="12">
              <CInput
                v-uppercase
                :label="$t('label.name')+' '+('(ES)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.eirTypeName')+' '+('(ES)')"
                addLabelClasses="required text-right"
                v-model.trim="$v.TpEirNameEs.$model"
                :is-valid="hasError($v.TpEirNameEs)"
                :invalid-feedback="errorMessage($v.TpEirNameEs)"
              >
              </CInput>
            </CCol>
            <CCol sm="12" lg="12">
              <CInput
                v-uppercase
                :label="$t('label.name')+' '+('(EN)')"
                :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                :placeholder="$t('label.eirTypeName')+' '+('(EN)')"
                addLabelClasses="required text-right"
                v-model.trim="$v.TpEirNameEn.$model"
                :is-valid="hasError($v.TpEirNameEn)"
                :invalid-feedback="errorMessage($v.TpEirNameEn)"
              >
              </CInput>
            </CCol>
     </CRow>    
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          type="submit"
          :disabled="isSubmit"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpEirNameEs: '',
    TpEirNameEn: '',
    apiStateLoading: false,
  };
}

function submitBody() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpEirJson = {
      TpEirNameEs: this.TpEirNameEs,
      TpEirNameEn: this.TpEirNameEn,
    };

    this.$http
      .post('TpEir-insert', TpEirJson, { root: 'TpEirJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpEirJson = data;
          this.resetForm();
          this.$emit('set-modal-type-eir-list', TpEirJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function resetForm() {
  this.TpEirNameEs = '';
  this.TpEirNameEn = '';
  this.$v.$reset();
}

export default {
  name: 'modal-type-eir-create',
  props: { modal: Boolean },
  data,
  mixins: [
    ModalMixin,
  ],
  validations: {
    TpEirNameEs: { required, maxLength: maxLength(30) },
    TpEirNameEn: { required, maxLength: maxLength(30) },
  },
  directives: UpperCase,
  methods: {
    submitBody,
    resetForm,
  },
};
</script>